
import Vue from "vue";
import HeaderTwo from "@/components/HeaderTwo.vue";
import Footer from "@/components/Footer.vue";
import SignUpMentoree from "@/components/SignUp/SignUpMentoree.vue";

export default Vue.extend({
  name: "SignUpPageMentoree",
  components: {
    HeaderTwo,
    Footer,
    SignUpMentoree
  }
});
